import categories from '../Components/Pages/Spinner/reducer';
import winner from '../Components/Pages/Spinner/reducer'
import gift from '../Components/Pages/Spinner/reducer';
import User from '../Components/Pages/Login/reducer'
import { combineReducers } from 'redux';
import common from "./reducer/common"
import episodes from '../Components/Pages/Episodes/reducer'

export default combineReducers({
  categories,
  winner,
  gift,
  User,
  common,
  episodes
})
