
import { saveObject } from '../../../utils';
import types from '../../../types'


const initalState = {
  isLoading: false,
  user:{}
}


export default function (state = { ...initalState }, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoading: true }

    case types.LOGIN_REQUEST_SUCCESS:
      saveObject("top-challenge-token", JSON.stringify(action.payload))
      return { ...state, isLoading: false, user: action.payload }
      
    case types.LOGIN_REQUEST_FAILED:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
