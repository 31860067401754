import React, { useEffect, useRef, useState } from 'react'
import SpinnerGift from '../Pages/Spinner/spinners/GiftsSpinner'
// import logo from '../../assets/images/logo.png'
import SecondSpinner from '../Pages/Spinner/spinners/MainSpinner';
import SpinnerText from '../Pages/Spinner/spinners/WinnersSpinner'
import { useHistory } from "react-router-dom"
import { ws } from '../../ws';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../Redux/action/common"
import { getAllCategories } from '../Pages/Spinner/action';
import { logOut } from '../../utils';
import logo from '../../assets/images/animation.gif'
var test;


function Screen() {
    const dispatch = useDispatch()
    const router = useHistory()
    const [type, setType] = useState('')
    const { socketData } = useSelector(state => state.common)

    useEffect(() => {
        dispatch(getAllCategories({ limit: 12 }))

            .then(res => {
                // ws.send(JSON.stringify({
                //   action: "onMessage",
                //   message: res
                // }));
            }).catch(() => { })

    }, [])

    const handleLogout = () => {
        logOut("top-challenge-token")
        logOut("loggedInAs")
        router.push('/')
    }

    useEffect(() => {
        if (socketData && socketData.type) {
            setType(socketData && socketData.type || "")
        }
        ws.onmessage = (data) => {
            const getData = data && data.data || ''
            const newData = JSON.parse(getData);

            let episodesData = JSON.parse(data && data.data)

            const { channel_id = '', id = '' } = episodesData && episodesData.data || {}
            if (!localStorage.getItem('channel_id') || (channel_id && localStorage.getItem('channel_id').id !== id))
                localStorage.setItem('channel_id', JSON.stringify({ channel_id, id }))

            dispatch(commonActions.setSocketData(newData))

        }

    }, [socketData])




    // useEffect(() => {
    //     router.push(`/screen?type=${type}`)
    // }, [type])
    function displayScreen(type) {
        switch (type) {
            case "category": return <SecondSpinner />
            case "gift": return <SpinnerGift />
            case "jury": return <SpinnerText />
            default: return <div className="main_container">
                <div className="spin-button-logout">
                    {/* <button onClick={() => handleLogout()} >LogOut </button> */}
                </div>
                <div className="cus-logo-sec">
                    <img className="logo_image logo-screen" src={logo} alt="" />
                </div>
            </div>
        }
    }
    return (
        <div>
            {displayScreen(type)}
        </div>
    )
}
export default Screen