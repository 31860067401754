import React, { useState, useEffect, useRef } from "react";
import logo from '../../../../assets/images/logo.png'
import culture from '../../../../assets/images/culture.svg'

import { useHistory } from "react-router-dom";

import {
  CircularProgress
} from '@material-ui/core';
import famous from '../../../../assets/images/famous.svg'
import science from '../../../../assets/images/science.svg'
import tornado from '../../../../assets/images/tornado.svg'
import geography from '../../../../assets/images/geography.svg'
import { useDispatch, useSelector } from "react-redux"
import * as categoriesActions from '../action'
import globe from "../../../../assets/images/globe.svg";
import Layout from "../../../common/Layout";
import { useLocation } from "react-router-dom"
import { ws } from "../../../../ws";
import { logOut } from "../../../../utils";
const initialWheel = [
  {
    id: 1,
    className: "span1",
    // title: 'hy',
    // image: science
  },
  {
    id: 2,
    className: "span2",
    // title: 'Math',
    // image: tornado
  },
  {
    id: 3,
    className: "span3",
    // title: 'General Culture',
    // image: famous
  },
  {
    id: 4,
    className: "span4",
    // title: 'Cinema',
    // image: science
  },
  {
    id: 5,
    className: "span5",
    // title: 'Challenger',
    // image: tornado
  },
  {
    id: 6,
    className: "span6",
    // title: 'Cinema',
    // image: culture
  },
  {
    id: 7,
    className: "span7",
    // title: 'Challenger',
    // image: culture
  },
  {
    id: 8,
    className: "span8",
    // title: 'Survival',
    // image: geography
  },
  {
    id: 9,
    className: "span9",
    // title: 'Sports',
    // image: science
  },
  {
    id: 10,
    className: "span10",
    // title: 'Famous',
    // image: culture
  },

  {
    id: 11,
    className: "span11",
    // title: 'Math',
    // image: tornado
  },
  {
    id: 12,
    className: "span12",
    // title: 'My Challenge',
    // image: geography
  },
]


// import './spinner.css'

function getTextToDisplay(text = "") {
  if (typeof text !== 'string') return
  let textArr = text && text.split(' ');

  if (text.length > 14 && textArr.length > 1) {
    return textArr.join('<br /> ')
  }
  return text
}


var b = ''

function SecondSpinner(props) {
  const dispatch = useDispatch()
  const { search = '' } = useLocation()
  const [catHeading, setCatHeading] = useState('')
  const [response, setResponse] = useState('')

  const getType = search && search.split('=')[1] || ''

  const { categories = [], isLoading } = useSelector(state => state.categories || {});

  const { episodes = [] } = useSelector(state => state.episodes || {});

  const { socketData } = useSelector(state => state.common)


  let cat = categories && categories.length ? categories.map((item) => {
    return { title: item.cat_name, image: item.image, id: item.id }
  }) : []

  let episode_id = localStorage.getItem('id')

  useEffect(() => {
    if (socketData && socketData.data == "spin the rotation" && socketData.id == episode_id) {
      setCatHeading('')
      startRotation()
      setResponse('')
    }
    else if (socketData && socketData.data == "spin the rotation" && socketData.id !== episode_id) {
      ws.send(JSON.stringify({
        action: "onMessage",
        message: JSON.stringify({ data: "id not match" })
      }));
    }
  }, [socketData])

  let wheelNames = [...initialWheel]

  wheelNames = wheelNames.map((item, i) => {
    const category = cat[i] || {};
    return {
      ...item,
      id: category && category.id || item.id,
      title: category && category.title && getTextToDisplay(category.title) || item.title,
      image: category && category.image || item.image
    }
  });

  useEffect(() => {
    setExistValue(wheelNames)
  }, [categories])



  const LetNumbers = [...wheelNames]
  const [name, setName] = useState("wheel");
  const [selectedOption, setSelectedOption] = useState(null);
  const [existValue, setExistValue] = useState([...wheelNames]);
  const history = useHistory();
  const [selectId, setSelectId] = useState('')

  const startRotation = () => {
    setName("wheel start-rotate");

    let updateExistArr = existValue.length > 0 ? [...existValue] : [...wheelNames];
    setSelectedOption(null)

    setTimeout(() => {
      setName("wheel start-rotate stop-rotate");

      // Actual Logic
      let gotValue = updateExistArr[Math.floor(Math.random() * updateExistArr.length)];

      // temp logic
      // let gotValue = updateExistArr[0];

      setCatHeading(gotValue && gotValue.title || '')
      const selectedId = gotValue && gotValue.id || ''
      setSelectId(selectedId)

      let findInd = LetNumbers.findIndex(val => val.className == gotValue.className);
      let findInd2 = updateExistArr.findIndex(val => val.className == gotValue.className);

      updateExistArr.splice(findInd2, 1);
      setExistValue(updateExistArr);
      setSelectedOption(findInd);
      let params = JSON.parse(localStorage.getItem('channel_id'))

      dispatch(categoriesActions.getQuestionsFromSelectedCategoryAPI(params, selectedId))
        .then(res => {
          ws.send(JSON.stringify({
            action: "onMessage",
            message: res
          }))
        })
        .catch((res) => {
          setResponse("Questions Not Available")
          ws.send(JSON.stringify({
            action: "onMessage",
            message: JSON.stringify({ data: "active buttons" })
          }));
        })


    }, Math.floor(Math.random() * 10000) + 1);
  };

  const handleLogout = () => {
    logOut("top-challenge-token")
    logOut("loggedInAs")
    history.push('/')
  }
  console.log(response, 'eres')

  if (isLoading) return <CircularProgress size={40} style={{ display: 'flex', alignItems: 'center', marginLeft: '45%', marginTop: '20%' }} />;
  return (
    <Layout >
      <div className="main_container">
        {/* <div className="spin-button-logout">
          <button onClick={() => handleLogout()} >LogOut </button>
        </div> */}
        <div className="cus-logo-sec">
          <img src={logo} className="logo_image" />
        </div>
        {catHeading && <h1 className="catHeading">{catHeading.replace(/<[^>]+>/g, '')}</h1>}
        {response && <h1 className="catHeading">{response}</h1>}

        <div className="spinner-div">
          <ul className={name} id="spinner-ul">

            {
              wheelNames.map((item, index, array) => {
                return <li key={index} className={selectedOption !== null ? selectedOption == index ? 'selected-list' : 'fade-list' : ""}>
                  <span className="text" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <img className="image" src={item.image} />
                </li>
              })
            }
          </ul>
          <div className="spin-center-second">
            <img src={globe} />
          </div>
        </div>
      </div >
    </Layout >
  );
}
export default SecondSpinner;
