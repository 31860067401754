import { IS_SOCKET_CONNECTED, SET_SOCKET_DATA, IS_SOCKET_REQUESTED, IS_SOCKET_FAILED, SET_QUESTIONS_DATA } from "../action/common"



const initalState = {
    socketData: {},
    socketConnected: false,
    isLoading: false,
    sockets: {},
    questions: {}

}


export default function (state = { ...initalState }, action) {
    switch (action.type) {
        case SET_SOCKET_DATA:
            return { ...state, socketData: action.data }

        case IS_SOCKET_CONNECTED: {
            return { ...state, socketConnected: action.data }
        }

        case SET_QUESTIONS_DATA: {
            return { ...state, questions: action.data }
        }

        default:
            return state
    }
}



